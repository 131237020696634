import customersModule from "./modules/customers";
import { createStore } from "vuex";
import router from "@/router";

export default createStore({
  state: {
    showOptions: true,
    customers: customersModule.state,
  },
  mutations: {
    changeRoute(state, value: string) {
      state.showOptions = !(value == "/login");
    },
  },
  actions: {
    initChangeRoute(context, path: string) {
      context.commit("changeRoute", path);
    },
    async logout() {
      localStorage.setItem("jwtToken", "");
      await router.push({
        name: "login",
      });
    },
  },
  modules: {
    customers: customersModule,
  },
});
