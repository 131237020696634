import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import Column from "primevue/column";
import "primevue/checkbox";
import "primevue/progressspinner";
import Datatable from "primevue/datatable";

const app = createApp(App);
app.use(store).use(router).use(PrimeVue);
app.component("p-column", Column);
app.component("p-datatable", Datatable);
app.mount("#app");
