import { Commit, Module } from "vuex";

const state = { customers: [] };

const actions = {
  setCustomerList({ commit }: { commit: Commit }, customers: any) {
    commit("setCustomers", customers);
  },
  editCustomer({ commit }: { commit: Commit }, customer: any) {
    commit("editCustomer", customer);
  },
  addCustomer({ commit }: { commit: Commit }, customer: any) {
    commit("addCustomer", customer);
  },
  removeCustomer({ commit }: { commit: Commit }, customer: any) {
    commit("removeCustomer", customer);
  },
};

const mutations = {
  setCustomers(state: any, customers: any) {
    state.customers = customers;
    localStorage.setItem("customers", JSON.stringify(state.customers));
  },
  editCustomer(state: any, customer: any) {
    state.customers.splice(state.customers.indexOf(customer), 1, customer);
  },
  addCustomer(state: any, customer: any) {
    state.customers.push(customer);
  },
  removeCustomer(state: any, customer: any) {
    state.customers.splice(state.customers.indexOf(customer), 1);
  },
};

const customers: Module<any, any> = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default customers;
